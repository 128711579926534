.title {
  font-size: 3rem;
  margin: 5px auto;
  margin-top: 50px;
  width: 80vw;
}

.desc {
  font-size: 1.25rem;
  margin: 0 auto;
  width: 60vw;
  font-weight: normal;
}

.coloredText {
  color: #70c396;
  font-weight: bolder;
}

.section {
  text-align: center;
}

@media screen and (max-width: 1500px) {
  .desc {
    width: 90vw;
  }
}

@media screen and (max-width: 1265px) {
  .title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 535px) {
  .title {
    font-size: 2.4rem;
  }
}

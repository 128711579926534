.header {
  background-color: #323e48;
}

.img {
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}

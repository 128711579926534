.included {
  font-size: 2rem;
  margin: 5px auto;
  margin-bottom: 0px;
  width: 90vw;
}

.emoji {
  font-size: 60px;
  padding: 0;
  margin: 0;
  margin-top: 40px;
}

div.parent {
  text-align: center;
  margin: 0;
}
ul {
  display: inline-block;
  text-align: left;
  font-size: 1rem;
  font-weight: normal;
  margin: 5px;
}

.link-text {
  text-align: center;
  width: 90vw;
  margin: 80px auto;
  font-size: 16px;
}

a:link {
  color: #70c396;
}
